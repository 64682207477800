import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "position-relative w-100" }
const _hoisted_2 = {
  key: 0,
  class: "position-absolute centered-item fade-animation text-center fs-8 fs-md-4 text-dark fw-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentLoader = _resolveComponent("ContentLoader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.animate)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(_ctx.defaultText), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.animate)
            ? (_openBlock(), _createBlock(_component_ContentLoader, {
                key: 1,
                class: "w-100",
                style: {"max-height":"300px"},
                animate: true,
                viewBox: "0 0 700 200"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    })
  ]))
}